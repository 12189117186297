<template>
	<div class="user-detail">
        <div class="parent">
            <div class="parent-body">
                <div class="row margin-0">
                    <div class="col-10 pad-left-0" v-if="actionData">
                        <template v-if="action.action==='UPDATE_PRODUCT'">
                            <div class="row margin-0">
                                <div class="col-6 pad-0">
                                    <h5>Old AD</h5>
                                    <product-info v-if="actionData" :product="actionData.data_details" :action="action.action" />
                                    <p v-else>Loading....</p>
                                </div>
                                <div class="col-6 pad-right-0" style="border-left: 1px dotted #ddd;border-right: 1px dotted #ddd;">
                                    <h5>New AD</h5>
                                    <product-info v-if="payloadObj && payloadObj.respond_action==='PARTIAL_UPDATE'" :product="action.data" @checkboxSelections="handleUpdateSelection" :mode="'PARTIAL'" :action="action.action" />
                                    <product-info v-else :product="action.data" :mode="null" :action="action.action" />
                                </div>
                            </div>
                        </template>
                        <template v-else-if="action.action==='NEW_PRODUCT' || action.action==='REPORT_PRODUCT'">
                            <div class="row margin-0">
                                <div class="col-12 pad-0" v-if="action.action==='REPORT_PRODUCT'">
                                    <h5>Report Details</h5>
                                    <div class="row margin-0">
                                        <div class="col-4 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Reason</label>
                                                <p>{{ actionData.data.reason || '-' }}</p>
                                            </div>
                                        </div>
                                        <div class="col-8 pad-left-0">
                                            <div class="info-block">
                                                <label for="">Comment</label>
                                                <p>{{ actionData.data.comment || '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 pad-0">
                                    <h5>AD Details</h5>
                                    <product-info v-if="actionData" :product="actionData.data_details" :action="action.action" />
                                    <p v-else>{{actionData}}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col-10 pad-15" v-else>
                        <span>Loading...</span>
                    </div>
                    <div class="col-2 pad-0" v-if="action.status!=='COMPLETED'&&action.status!=='REJECTED'">
                        <div class="row margin-0">
                            <div class="col-12 pad-0">
                                <div class="form-group">
                                <label for=""><b>Response Action</b></label>
                                <select
                                    name=""
                                    id=""
                                    v-model="payloadObj.respond_action"
                                    class="form-control form-control-sm"
                                >
                                    <option :value="null">Nothing</option>
                                    <template v-if="action.action==='NEW_PRODUCT'">
                                        <option value="ACTIVE">Make Active</option>
                                        <option value="REJECT">Reject Changes</option>
                                        <option value="DRAFT">Request Changes</option>
                                    </template>
                                    <template v-if="action.action==='UPDATE_PRODUCT'">
                                        <option value="UPDATE">Update Changes</option>
                                        <option value="PARTIAL_UPDATE">Partial Update</option>
                                        <option value="REJECT">Reject Changes</option>
                                    </template>
                                    <template v-if="action.action==='REPORT_PRODUCT'">
                                        <option value="SKIP">SKIP</option>
                                        <option value="INACTIVE">Make InActive AD</option>
                                        <option value="HOLD">Hold AD</option>
                                        <option value="BLOCK">Block AD</option>
                                    </template>
                                    <template v-if="action.action==='COIN_DISPUTE'">
                                        <option value="REFUND">REFUND</option>
                                    </template>
                                        <template v-if="action.action==='SUBSCRIPTION_DISPUTE'">
                                        <option value="REFUND">REFUND</option>
                                    </template>
                                    <template v-else-if="action.action==='ACCOUNT_CREATION' || action.action==='ACTIVATE_ACCOUNT'">
                                        <option value="MAKEACTIVE">MAKE ACTIVE</option>
                                    </template>
                                    <template v-else-if="action.action==='SUSPEND_ACCOUNT'">
                                        <option value="SUSPEND">SUSPEND ACCOUNT</option>
                                    </template>
                                    <template v-else-if="action.action==='CLOSE_ACCOUNT'">
                                        <option value="CLOSE">CLOSE ACCOUNT</option>
                                    </template>
                                </select>
                                </div>
                            </div>
                            <div class="col-12 pad-0">
                                <div class="form-group">
                                <label for=""><b>Status</b></label>
                                <select name="" id="" v-model="payloadObj.status" class="form-control form-control-sm">
                                    <option value="COMPLETED">COMPLETED</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="REJECTED">REJECTED</option>
                                </select>
                                </div>
                            </div>
                            <div class="col-12 pad-0" v-if="action.action==='COIN_DISPUTE' && payloadObj.respond_action==='REFUND'">
                                <div class="form-group">
                                    <label for="">Coins</label>
                                    <input
                                        type="number"
                                        v-model="payloadObj.respond_value"
                                        class="form-control form-control-sm"
                                        placeholder="Enter Value"
                                    />
                                </div>
                            </div>
                            <div class="col-12 pad-0">
                                <div class="form-group">
                                <label for=""><b>Feedback</b></label>
                                <textarea name="" v-model="payloadObj.comments" class="form-control form-control-sm" placeholder="Comments here..." id="" cols="30" rows="5"></textarea>
                                <span class="error" v-if="error && error.comments">{{error.comments}}</span>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button
                                    @click="decideAction(payloadObj)"
                                    class="btn btn-success btn-sm"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataMixin from "@/mixins/dataMixin";
import ProductInfo from './ProductInfo.vue';
export default {
    props: ['id', 'action', 'pay'],
    components: {
        ProductInfo
    },
    data() {
        return {
            actionData: null,
            payloadObj: {}
        }
    },
    created() {
        this.getdataList();
        this.payloadObj = {
            ...this.pay,
            respond_action: null
        }
    },
    mixins: [DataMixin],
    methods: {
        getdataList() {
            const that = this;
            that.$cs.action.list({
                resource: `${that.$cs.action.api}${that.id}/details/`,
            }).then(
                (data) => {
                    that.actionData = data.data;
                },
                () => {
                    console.log("error");
                }
            );
        },
        decideAction() {
            const that = this;
            const finalPayload = {...that.payloadObj};
            that.$cs.action.create({
                resource: `${that.$cs.action.api}${that.id}/finalize/`,
                data: finalPayload
            }).then(
                (data) => {
                    that.$emit('updated');
                },
                () => {
                    console.log("error");
                }
            );
        },
        handleUpdateSelection(value) {
            console.log(value);
            this.payloadObj.respond_value = value;
        }
    }
}
</script>
